.btn {
  @apply inline-flex items-center justify-center rounded-lg border-2 py-2 px-3 space-x-4 text-sm leading-none cursor-pointer bg-white transition-all sm:px-8 focus:outline-none focus:ring-0;
  &:hover {
    @apply bg-gray-50 border-gray-50;
  }

  &-outline {
    @apply bg-transparent text-current border-current;
    &:hover {
      @apply border-current opacity-95;
    }
  }
  &-link {
    @apply px-0 underline border-none normal-case font-medium text-p2-500 bg-transparent space-x-2;
    svg {
      @apply w-4 h-4;
    }
    &:hover {
      @apply bg-transparent opacity-80;
    }
  }
  &-accent {
    @apply border-gray-200 text-gray-600 bg-white;
    &:hover {
      @apply bg-opacity-80 text-gray-900 border-opacity-80;
    }
  }
  &-dark {
    @apply border-p1-600 text-white bg-p1-600;
    &:hover {
      @apply bg-p1-600 bg-opacity-80 border-p1-600 border-opacity-80;
    }
  }

  &-tab {
    @apply font-normal border-current border-0 opacity-80 rounded-none px-6 py-4;

    &.active {
      @apply font-black border-b-2 opacity-100;
    }
  }
}
