@media print { 
  body, html, #wrapper {
    height: 100%;
  }

  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }
  body {
    margin: 0;
  }

  .custom {
    height: 100%;
  }

  .page-footer, .page-footer-space {
    height: 50px;
  }
  .page {
    page-break-after: always;
  }

  #wrapper {
    @apply print:pt-0 print:overflow-visible;
  }

  #box_print {
    @apply print:bg-white print:px-4 print:py-2 print:mb-8 print:shadow-none print:flex print:flex-col print:items-center;
  }
}