table.table {
  @apply min-w-full divide-y divide-gray-200;
  @apply shadow overflow-hidden border-b border-gray-200 sm:rounded-lg;

  thead {
    @apply bg-gray-50;
    tr {
      th {
        @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
      }
    }
  }

  tbody {
    @apply bg-white divide-y divide-gray-200;
    tr {
      td {
        // @apply px-6 py-4 whitespace-nowrap; //CHECK ME
        @apply px-6 py-4;
      }
    }
  }
}
