.#{$select-ns}-wrapper.plugin-remove_button{

	.item{
		display:		inline-flex;
		align-items:	center;
		padding-right:	0 !important;
	}

	.item .remove {
		color:				inherit;
		text-decoration:	none;
		vertical-align:		middle;
		display:			inline-block;
		padding:			0 $select-padding-item-x;
		border-left:		1px solid $select-color-item-border;
		border-radius:		0 2px 2px 0;
		box-sizing:			border-box;
		margin-left:		$select-padding-item-x;
	}

	.item .remove:hover {
		background: rgba(0,0,0,0.05);
	}

	.item.active .remove {
		border-left-color: $select-color-item-active-border;
	}

	&.disabled .item .remove:hover {
		background: none;
	}

	&.disabled .item .remove {
		border-left-color: lighten(desaturate($select-color-item-border, 100%), $select-lighten-disabled-item-border);
	}

	.remove-single {
		position: absolute;
		right: 0;
		top: 0;
		font-size: 23px;
	}

}
