@import "trix/dist/trix.css";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "actiontext";

@import "components/buttons";
@import "components/table";
@import "flatpickr";
@import "tom-select";
@import "components/markdown";
@import "components/print_style";

input:checked~.dot {
  transform: translateX(100%);
  background-color: #fff;
}

input:checked~.background {
  background-color: #86704D;
}

trix-editor.form-control {
  height: auto;
}

* {
  font-family: "Inter", sans-serif;
}

// .custom-switch-input {
//   @apply p-2;
// }

.panel {
  @apply transition-all ease-in transform translate-x-0;

  &.out {
    @apply transition-all ease-out transform translate-x-full;
  }

  &-bg {
    @apply z-20 bg-gray-700 bg-opacity-50 fixed top-0 left-0 bottom-0 right-0 opacity-100 visible transition-all ease-in;

    &.out {
      @apply transition-all ease-out opacity-0 invisible;
    }
  }
}

.modal {
  @apply transition-all ease-in transform translate-x-0;

  &.out {
    @apply transition-all ease-out transform translate-x-full;
  }

  &-bg {
    @apply z-20 bg-gray-700 bg-opacity-50 fixed top-0 left-0 bottom-0 right-0 opacity-100 visible transition-all ease-in;

    &.out {
      @apply transition-all ease-out opacity-0 invisible;
    }
  }
}

#sidebar {
  .menu-item {
    @apply transition-all text-gray-300;

    &.active {
      @apply bg-gray-900;

      &:hover {
        @apply bg-gray-700 text-white;
      }
    }

    &:hover {
      @apply bg-gray-700 text-white transition-all;
    }
  }
}

input:disabled {
  @apply bg-gray-200 border-gray-500;
}

.header-title {
  @apply py-4 text-2xl font-bold text-p2-570
}

.wellness-prog-status {
  .left-20 {
    left: 8%;
  }

  .left-40 {
    left: 28%;
  }

  .left-60 {
    left: 48%;
  }

  .left-80 {
    left: 68%;
  }

  .left-100 {
    left: 88%;
  }
}

.svg-mindset {
  color: #FDA349;
}

.svg-nutrition {
  color: #FDA0AB;
}

.svg-fitness {
  color: #7ABBE1
}

.svg-health {
  color: #91D06F
}

.svg-lifestyle {
  color: #935F95;
}

@import "tailwindcss/utilities";
